import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import '../css/Likes.css'
import VerifiedIcon from '@mui/icons-material/Verified';
import InteractButtons from './InteractButtons';


const Pictures = ({album}) =>{
    return (
        <>
            <div className='about_title'>
                <img className='profile_pic_thumbnail' src='https://i.imgur.com/82GWSbz.jpg' alt='thumbnail pic' />
                <div className="post_title">
                    Womeo
                <VerifiedIcon className='about_verified_icon' color='primary'/>
                </div>
            </div>

            <div className="post_description">
                {album.month} {album.year} 
            </div>

            <Box sx={{ width: '80%', marginLeft: '10%',   }}>
                <ImageList variant="masonry" cols={3} gap={4}>
                    {album.picture_links.map((link) => (
                    <ImageListItem key={link}>
                        <img
                        src={`${link}?w=248&fit=crop&auto=format`}
                        onClick={()=>window.open(`${link}`, '_blank')}
                        srcSet={`${link}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt='womeo pic not found!'
                        loading="lazy"
                        className='womeo_image'
                        />
                    </ImageListItem>
                    ))}
                </ImageList>
            </Box>
            <InteractButtons Liked={true}/>

            <hr />
        
        </>
    )
}

export default Pictures;