import React from "react";
import Particles from "react-tsparticles"
import { loadFull } from "tsparticles";
import { useCallback } from "react";


const ParticlesBackground = ({isDarkMode}) => {
    const particlesInit = useCallback(async engine => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

	const particlesLoaded = useCallback(async container => {
        // await console.log(container);
    }, []);


    return(
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                particles: {
                  number: {
                    value: 200
                  },
                  reduceDuplicates: true,
                  rotate: {
                    animation: {
                      enable: true,
                      speed: { min: 2, max: 5 }
                    }
                  },
                  tilt: {
                    enable: true,
                    value: {
                      min: 0,
                      max: 180
                    },
                    animation: {
                      enable: true,
                      speed: { min: 5, max: 10 }
                    }
                  },
                  roll: {
                    darken: {
                      enable: true,
                      value: 25
                    },
                    enlighten: {
                      enable: true,
                      value: 25
                    },
                    enable: true,
                    speed: {
                      min: 5,
                      max: 15
                    }
                  },
                  wobble: {
                    distance: 30,
                    enable: true,
                    speed: {
                      min: -15,
                      max: 15
                    }
                  },
                  shape: {
                    // type: isDarkMode? 'hearts' :'circle',
                    type: 'circle',
                    options: {
                      hearts: {
                        particles: {
                          color: {
                            value: "#ff0000"
                          }
                        }
                      },
            
                    }
                  },
                  opacity: {
                    value: 1
                  },
                  size: {
                    // value: isDarkMode?  20:3
                    value: 3
                  },
                  move: {
                    enable: true,
                    speed: 2,
                    direction: "none"
                  }
                },
                background: {
                    color: {
                      // value: `#feb9ee`
                      // value: '#4bbadb'
                      // value: `#000000`
                      // value: '#e795c6'
                      value: isDarkMode? '#465488':'#7188d8' 
                    },
                    image: "",
                    position: "",
                    repeat: "",
                    size: "",
                    opacity: 1
                  },
                  fullScreen: {
                    enable: true,
                    zIndex: -1
                    }, 
                    fpsLimit: 120,
            
              }}
        />
    )
}

export default ParticlesBackground