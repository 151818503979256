import '../css/Contact.css'
import VerifiedIcon from '@mui/icons-material/Verified';
import ContactForm from './ContactForm';

const Contact = () =>{
    return (
        <div className='contact'>
            <div className='about_title'>
                <img className='profile_pic_thumbnail' src='profile_pic.jpeg' alt='thumbnail pic' />
                <div className="post_title">
                    Jason Velarde
                <VerifiedIcon className='about_verified_icon' color='primary'/>
                </div>
            </div>

            <ContactForm />
            <br></br>
            <hr></hr>
        </div>
    )
}

export default Contact