import '../css/Project.css'
import VerifiedIcon from '@mui/icons-material/Verified';
import InteractButtons from './InteractButtons';

const Projects = ({post, selectedTab}) =>{

    const showTab = () =>{
        switch(selectedTab){
            case ('posts'):
                return(
                    <>
                        <div className='about_title'>
                            <img className='profile_pic_thumbnail' src='https://i.imgur.com/TlaefDw.jpeg' alt='thumbnail pic' />
                            <div className="post_title">
                                Monpi
                            <VerifiedIcon className='about_verified_icon' color='primary'/>
                            </div>
                        </div>
                    </>
                )
            case ('about'):
                return (
                    <>
                        <div className='about_title'>
                            <img className='profile_pic_thumbnail' src={post.profile_src} alt='thumbnail pic' />
                            <div className="post_title">
                                {post.name}
                            <VerifiedIcon className='about_verified_icon' color='primary'/>
                            </div>
                        </div>
                    </>
                )
            case ('likes'):
                return(
                    <>
                        <div className='about_title'>
                            <img className='profile_pic_thumbnail' src='https://i.imgur.com/82GWSbz.jpg' alt='thumbnail pic' />
                            <div className="post_title">
                                Womeo
                            <VerifiedIcon className='about_verified_icon' color='primary'/>
                            </div>
                        </div>
                    </>
                )
            default:
                return ''
        }
    }



    return (
        <>
            <div className="projects">

                {/*Monpi profile pic and name*/}

                {showTab()}

                <div className="post_description">
                     {post.description}
                </div>

                <div className='post_quote'>
                    {post.quote}
                </div>
                
                {/* Post picture if it exists */}
                {post.img_link ? 
                    <div >
                        <img className="post_picture" src={post.img_link} alt='yoke ss'/>
                    </div>
                    : 
                    ''
                }

                <InteractButtons />

                {/* <div className='socials_buttons'>
                    <div className='action_button replies_button' 
                        onClick={handleReply}
                        onMouseEnter={handleReplyEnter}
                        onMouseLeave={handleReplyLeave}
                    >
                        {
                            isReplied ?
                                <>
                                    <ChatBubbleRoundedIcon sx={{ fontSize: 18 }} style={{color: '#5ed9ff'}}/>
                                </>
                                :
                                <>
                                    <ChatBubbleOutlineRoundedIcon sx={{ fontSize: 18 }} />
                                </>
                        }
                        <p style={isReplied? {color: '#5ed9ff'}: {}}>{random_replies}k</p>
                        <div className={`replies_shadow ${isReplyHover? '' : 'hidden'}`}></div>
                    </div>

                    <div className='action_button retweet_button' 
                        onClick={handleRetweet}
                        onMouseEnter={handleRetweetEnter}
                        onMouseLeave={handleRetweetLeave}
                    >
                        {
                            isRetweeted ?
                                <>
                                    <RepeatRoundedIcon sx={{ fontSize: 18 }} style={{color: '#6ae630'}}/>
                                </>
                                :
                                <>
                                    <RepeatRoundedIcon sx={{ fontSize: 18 }} />
                                </>
                        }
                        <p style={isRetweeted? {color: '#6ae630'}: {}}>{random_retweets}k</p>
                        <div className={`retweet_shadow ${isRetweetHover? '' : 'hidden'}`}></div>
                    </div>

                    <div className='action_button likes_button' 
                        onClick={handleLike}
                        onMouseEnter={handleLikeEnter}
                        onMouseLeave={handleLikeLeave}    
                    >
                        {
                            isLiked ?
                                <>
                                    <FavoriteRoundedIcon sx={{ fontSize: 18 }} style={{color: '#f91880'}}/>
                                </>
                                :
                                <>
                                    <FavoriteBorderRoundedIcon sx={{ fontSize: 18 }} />
                                </>
                        }
                        <p style={isLiked? {color: '#f91880'}: {}}>{random_likes}k</p>
                        <div className={`like_shadow ${isLikeHover? '' : 'hidden'}`}></div>
                    </div>
                    

                    <div className='action_button share_button'
                        onMouseEnter={handleShareEnter}
                        onMouseLeave={handleShareLeave} 
                    >
                        <UploadIcon sx={{ fontSize: 18 }} />
                        <div className={`share_shadow ${isShareHover? '' : 'hidden'}`}></div>
                    </div>

                    <div className={`share_message ${isShareHover? '' : 'hidden'}`}> 
                            <img src='https://i.imgur.com/IFelLjp.png' alt='holdup'/>
                            no!
                    </div>
                </div> */}

            </div>
            <hr />
        </>
    )    
}

export default Projects;