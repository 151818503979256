
import React, { useState } from 'react';
// eslint-disable-next-line 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// eslint-disable-next-line 
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// eslint-disable-next-line 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../css/Likes.css'
import Pictures from './Pictures';
import pictures from './config/pictures-config';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Likes = () =>{

    const loadingObject = {
        month: "Loading...",
        month_num: "", 
        year: "",
        picture_links: []
    };
    const [month, setMonth] = useState(NaN);
    const [year, setYear] = useState(NaN);
    const [chosenDate, setChosenDate] = useState(loadingObject);
    const [filter, setFilter] = useState(true);
    const [filteredArr, setFilteredArr] = useState(pictures);
    
    const handleChange = (date) =>{
        if (date != null){
            setChosenDate(loadingObject);
            setMonth(date.month());
            setYear(date.year());
            pictures.forEach(album => {
                if (album.month_num === date.month() && album.year === date.year()){
                    setChosenDate(album)
                }
            });

        } 
    }

    const handleClick = () =>{
        setFilter(prevFilter => !prevFilter);
        setFilteredArr([...filteredArr].reverse())
    }

    return (
        <>
            <div className='date_div'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            className='date_picker' 
                            label={'Month/Year'} 
                            views={['month', 'year']} 
                            onChange={handleChange}
                        />
                </LocalizationProvider>
                
                <div className='filter' onClick={handleClick}>
                    {
                        filter ? 
                        <KeyboardArrowUpIcon />
                        :
                        <KeyboardArrowDownIcon />
                    }

                </div>
            </div>


            {
                isNaN(month)  || isNaN(year) ? 
                filteredArr.map((album, index) => (
                    <Pictures album={album} key={index} />
                )) 
                :
                    <Pictures album={chosenDate} key={chosenDate.month} />
            }

        </>
    )
}

export default Likes