import UploadIcon from '@mui/icons-material/Upload';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import { useState, useMemo } from 'react';
import '../css/InteractButtons.css'


const InteractButtons = ({Liked}) =>{

    const [isReplied, setIsReplied] = useState(false);
    const [isRetweeted, setIsRetweeted] = useState(false);
    const [isLiked, setIsLiked] = useState(Liked ? true:false);
    const [isReplyHover, setIsReplyHover] = useState(false);
    const [isRetweetHover, setIsRetweetHover] = useState(false);
    const [isLikeHover, setIsLikeHover] = useState(false);
    const [isShareHover, setIsShareHover] = useState(false);



    const handleReply = () =>{
        setIsReplied(old => !old)
    }

    const handleReplyEnter = () =>{
        setIsReplyHover(true)
    }

    const handleReplyLeave = () =>{
        setIsReplyHover(false)
    }

    const handleRetweet = () =>{
        setIsRetweeted(old => !old)
    }

    const handleRetweetEnter = () =>{
        setIsRetweetHover(true)
    }

    const handleRetweetLeave = () =>{
        setIsRetweetHover(false)
    }

    const handleLike = () =>{
        setIsLiked(old => !old)
    }

    const handleLikeEnter = () =>{
        setIsLikeHover(true)
    }

    const handleLikeLeave = () =>{
        setIsLikeHover(false)
    }

    const handleShareEnter = () =>{
        setIsShareHover(true)
    }

    const handleShareLeave = () =>{
        setIsShareHover(false)
    }


    const random_replies = useMemo(()=>{
        return [Math.floor(Math.random() * 10) +2];
    }, [])

    const random_retweets = useMemo(()=>{
        return [Math.floor(Math.random() * 20) +2];
    }, [])

    const random_likes = useMemo(()=>{
        return [Math.floor(Math.random() * 40) +2];
    }, [])


    return (
        <div className='socials_buttons'>
            <div className='action_button replies_button' 
                onClick={handleReply}
                onMouseEnter={handleReplyEnter}
                onMouseLeave={handleReplyLeave}
            >
                {
                    isReplied ?
                        <>
                            <ChatBubbleRoundedIcon sx={{ fontSize: 18 }} style={{color: '#5ed9ff'}}/>
                        </>
                        :
                        <>
                            <ChatBubbleOutlineRoundedIcon sx={{ fontSize: 18 }} />
                        </>
                }
                <p style={isReplied? {color: '#5ed9ff'}: {}}>{random_replies}k</p>
                <div className={`replies_shadow ${isReplyHover? '' : 'hidden'}`}></div>
            </div>

            <div className='action_button retweet_button' 
                onClick={handleRetweet}
                onMouseEnter={handleRetweetEnter}
                onMouseLeave={handleRetweetLeave}
            >
                {
                    isRetweeted ?
                        <>
                            <RepeatRoundedIcon sx={{ fontSize: 18 }} style={{color: '#6ae630'}}/>
                        </>
                        :
                        <>
                            <RepeatRoundedIcon sx={{ fontSize: 18 }} />
                        </>
                }
                <p style={isRetweeted? {color: '#6ae630'}: {}}>{random_retweets}k</p>
                <div className={`retweet_shadow ${isRetweetHover? '' : 'hidden'}`}></div>
            </div>

            <div className='action_button likes_button' 
                onClick={handleLike}
                onMouseEnter={handleLikeEnter}
                onMouseLeave={handleLikeLeave}    
            >
                {
                    isLiked ?
                        <>
                            <FavoriteRoundedIcon sx={{ fontSize: 18 }} style={{color: '#f91880'}}/>
                        </>
                        :
                        <>
                            <FavoriteBorderRoundedIcon sx={{ fontSize: 18 }} />
                        </>
                }
                <p style={isLiked? {color: '#f91880'}: {}}>{random_likes}k</p>
                <div className={`like_shadow ${isLikeHover? '' : 'hidden'}`}></div>
            </div>
            

            <div className='action_button share_button'
                onMouseEnter={handleShareEnter}
                onMouseLeave={handleShareLeave} 
            >
                <UploadIcon sx={{ fontSize: 18 }} />
                <div className={`share_shadow ${isShareHover? '' : 'hidden'}`}></div>
            </div>

            <div className={`share_message ${isShareHover? '' : 'hidden'}`}> 
                    <img src='https://i.imgur.com/IFelLjp.png' alt='holdup'/>
                    no!
            </div>
        </div>
    )
}

export default InteractButtons