const projects = [
    {
        id: 0,
        description: "When referencing a Dorito shaped man (see diagram)",
        quote: "Call him a SNACC",
        img_link: "https://i.imgur.com/NAamrqR.jpg" 
    },
    {
        id: 1,
        quote: "We don't want those chickens COOPED up in there"
    },
    {
        id: 2,
        description: "Whenever the topic shifts to fungi cutlery",
        quote: "FUNGI for a fun guy"
    },
    {
        id: 3,
        description: "When committing theft of persons or objects named Larry",
        quote: "I'll be committing a burg-LARRY"
    },
    {
        id: 4,
        img_link: "https://i.imgur.com/XXGA6Du.png"
    },
    {
        id: 5,
        description: "When discussing the aquisition of two (2) cans",
        quote: "I have TOUCANS",
        img_link: "https://i.imgur.com/PUbQaKd.png"
    },
    {
        id: 6,
        description: "When discussing the growth of the root vegetable solanum tuberosum (potato) in human excrement",
        quote: "POOTATOES LMAOOOOO"
    },
    {
        id: 7,
        description: "An app that lets you play the lotto called",
        quote: "LOTT'O LOTTO"
    },
    {
        id: 8,
        description: "How to come up with golden jokes/ideas",
        img_link: "https://i.imgur.com/RzqHntA.jpg"
    },
    {
        id: 9,
        description: "After a break from discussing delicious legumes",
        img_link: "https://i.imgur.com/eAzavBb.png"
    },
    {
        id: 10,
        description: "When disussing pen names",
        img_link: "https://i.imgur.com/I3sqKGE.png"
    },
    {
        id: 11,
        description: "When referring to Zuko",
        quote: "He's a lil AANGsty"
    },
    {
        id: 12,
        img_link: "https://i.imgur.com/jFfJpVf.png"
    },
    {
        id: 13,
        description: "When referring to potato shaped kittens in Spanish",
        quote: "GATATOS"
    },
    {
        id: 14,
        quote: "BROCCOBAMA",
        img_link: "https://i.imgur.com/KHzmXdz.png"
    },
    {
        id: 15,
        quote: "Time is a mystery"
    },
    {
        id: 16,
        img_link: "https://i.imgur.com/WBA6Zpf.jpg"
    },
    {
        id: 17,
        img_link: "https://i.imgur.com/f2wOxEf.jpg"
    },
    {
        id: 18,
        img_link: "https://i.imgur.com/7tM43ly.jpg" //HAN SOLO TWEET
    },
    {
        id: 19,
        img_link: "https://i.imgur.com/j2bR8H5.jpg"
    },
    {
        id: 20,
        img_link: "https://i.imgur.com/WWNrRJs.jpg"
    },
    {
        id: 21,
        quote: "Jabetis NUTZ"
    },
    {
        id: 22, 
        description: "What do French people smoke",
        quote: "Ouid"
    }
  ]

export default projects;