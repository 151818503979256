import '../css/NoAccess.css'
import React, { useState } from 'react';

const WOMEO_PASSWORD = process.env.REACT_APP_WOMEO_PASSWORD; 

const NoAccess = ({handlePasswordCheck}) =>{

    const [textInput, setTextInput] = useState('');

    const handleInputChange = event => {
        const newValue = event.target.value;
        setTextInput(newValue);
    };

    

    const handleSubmit = (e) =>{
        e.preventDefault();
        const password_input = document.getElementById('password_input')
        
        if (textInput !== WOMEO_PASSWORD){
            password_input.classList.add('shake')
            
            setTimeout(() => {
                password_input.classList.remove('shake');
            }, 500);
        }else{
            console.log("YOU'RE IN")
        }
        handlePasswordCheck(textInput);
        setTextInput('');
        handlePasswordCheck(textInput);
    }

    return (
        <div className="no_access">
            <h1>HEY</h1>
            <img src='https://i.imgur.com/TjeuEnp.png' alt='no_access pic' />
            <h1>What's the password?</h1>
            <form onSubmit={handleSubmit}>
                <input id='password_input' type='password' value={textInput} onChange={handleInputChange} className='password_box' name='password'/>
                <button type='submit' className='submit_button'>Enter</button>
            </form>
        </div>
    )
}

export default NoAccess