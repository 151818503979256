import React from "react"
import '../css/Navbar.css'

const Navbar = ({selectNav, selectedNav, isDarkMode}) =>{

    const selected = (isDarkMode) => {
        return isDarkMode ? 'selected' : 'selected_light'
    }

    return (
        <div>
            <div className="navbar">
                <div className="navItem" onClick={() => selectNav('about')}>
                    <div className={`${selectedNav === 'about' ? selected(isDarkMode) : ''}`}>About</div>
                    <div className={`nav_selector ${selectedNav === 'about' ? 'selected' : 'hidden'}`}></div>
                </div>

                <div className="navItem" onClick={() => selectNav('posts')} >
                    <div className={`${selectedNav === 'posts' ? selected(isDarkMode) : ''}`}>Posts</div>
                    <div className={`nav_selector ${selectedNav === 'posts' ? 'selected' : 'hidden'}`}></div>
                </div>

                <div className="navItem" onClick={() => selectNav('likes')}>
                    <div className={`${selectedNav === 'likes' ? selected(isDarkMode) : ''}`}>Likes</div>
                    <div className={`nav_selector ${selectedNav === 'likes' ? 'selected' : 'hidden'}`}></div>
                </div>
                
                {/* <div className="navItem" onClick={() => selectNav('contact')}>
                    <div className={`${selectedNav === 'contact' ? selected(isDarkMode) : ''}`}>Contact</div>
                    <div className={`nav_selector ${selectedNav === 'contact' ? 'selected' : 'hidden'}`}></div>
                </div> */}
            </div>
        
            <hr/>
        </div>
    )
}

export default Navbar