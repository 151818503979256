const testimonials = [
    {
        name: "Womeo",
        description: "@Monpi is my biological mother despite me somehow being teleported to France moments after my birth :< \n \n When I made my way to the States, I messed up the address and was stuck with a nice young lad and his not-as-nice mother. I stayed with them for a year while I found my real mother's address :< \n \n When I finally found my way back to mom, I was crusty and stinky. She nursed me back to full health. I'm the now happiest and healthiest I've ever been :3 \n \n  I'm trying to repay Mamon so every day I patrol the house to protect her. One day I'll get the courage to step on the blankets :3 \n \n (Thoughts transcribed through espn by sok/pat) ",
        profile_src: 'https://i.imgur.com/82GWSbz.jpg'
    },
    {
        name: "evil_little_imposter",
        description: "@womeo nice fluffy little baby body you got there. Meet me in the alley tn",
        profile_src: "https://i.imgur.com/TQjuMUt.jpeg",
    },
    {
        name: "wise_old_man",
        description: "@Monpi Hey I finished the contract you were talking about just have womeo sign it",
        profile_src: "https://i.imgur.com/7vSqptW.jpeg",
    },
    {
        name: "Hachiware",
        description: "@Monpi Charimera? :D",
        profile_src: "https://i.imgur.com/A3sdJ9o.jpeg",
    },
    {
        name: 'Bobert',
        description: "@Monpi HEY! I STILL BELIEVE IN YOU! 😡😡 \n \n Japan 20XX?",
        profile_src: 'https://i.imgur.com/AdyBq0j.jpeg',
    },
    {
        name: 'The_Strongest',
        description: "@Monpi 😎",
        profile_src: 'https://i.imgur.com/o169Tw1.jpg',
    },
    {
        name: 'FifthCappy',
        description: "@Monpi ¿ooʇ sʇᴉɟ ʎɯ ɟo ǝɯos ʍǝᴉʌǝɹ noʎ plnoƆ ¿uossǝl ʇnɔɹᴉɐɥ ǝɥʇ uo pooƃ llᴉʇs ǝʍ ǝɹɐ ʎǝH",
        profile_src: 'https://i.imgur.com/5orxZtC.jpg',
    },
    {
        name: 'driving_mastermind_tatsumi',
        description: "@Monpi I passed the test! Let's go cruising sometime",
        profile_src: 'https://i.imgur.com/sWDd1nJ.jpg',
    },
]

export default testimonials