import './App.css';
import Header from './components/Header';
import Navbar from './components/Navbar';
import React, { useState, useEffect } from 'react';
import Projects from './components/Projects';
import projects from './components/config/projects_info';
// import experiences from './components/config/experience-info';
// import About from './components/About';
import Contact from './components/Contact';
import testimonials from './components/config/testimonials_info';
import './css/About.css'
import Likes from './components/Likes';
import NoAccess from './components/NoAccess';
import ParticlesBackground from './components/ParticlesBackground';
import ContactForm from './components/ContactForm';

const WOMEO_PASSWORD = process.env.REACT_APP_WOMEO_PASSWORD; 

function App() {

  const storedWomeoPassword = localStorage.getItem('womeo_password') || ''
  const storedDarkMode = localStorage.getItem('womeo_dark_mode') === 'true'
  const [isDarkMode, setIsDarkMode] = useState(storedDarkMode);
  const [selectedNav, setSelectedNav] = useState("about");
  const [checkPassword, setCheckPassword] = useState(storedWomeoPassword);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };
  
  useEffect(()=>{
    localStorage.setItem('womeo_dark_mode', isDarkMode);
  }, [isDarkMode])


  const selectNav = (nav) => {
    setSelectedNav(nav);
  }

  const handlePasswordCheck = (pw_string) =>{
    setCheckPassword(pw_string);
    localStorage.setItem('womeo_password', pw_string)
  }

  const getTab = (selectedTab) =>{
    switch(selectedTab){
      case 'about':
        return (
        <>
          {
            testimonials.map((post, index) => (
              <Projects post={post} key={index} selectedTab={selectedTab}/>
            ))
          }
        </>)
        
      case 'posts':
        return <>
          <ContactForm />
          {
            projects.map((post, index) => (
              <Projects post={post} key={index} selectedTab={selectedTab}/>
            ))
          }
        </>
      case 'likes':
        // return experiences.map((post, index) => (
        //   <Projects post={post} key={index} selectedTab={selectedTab}/>
        // ))
        return (
          <>
            {(checkPassword === WOMEO_PASSWORD) ?  
            <Likes />
          :
            <NoAccess handlePasswordCheck={handlePasswordCheck}/>
          }
          </>
        )
      case 'contact':
        return <Contact />;
      default:
        return '';
    }
  }


  return (
    <>
      <div className={`App ${isDarkMode ?'dark_mode' : '' }`}>
        <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode}/>
        <Navbar selectNav={selectNav} selectedNav={selectedNav} isDarkMode={isDarkMode}/>
        {getTab(selectedNav)}
      </div>
      
      <ParticlesBackground isDarkMode={isDarkMode}/>
    </>
  );
}

export default App;
